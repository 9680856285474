import React, { useState } from 'react';
import Layout from './layout';
import { Mobile, PC } from './mediaQuery';

import * as styles from '../styles/policies.module.css';

export default function Policy({ location, data }) {
  const [activeIdx, setActiveIdx] = useState(location?.hash?.slice(1) || 0);

  const handleClickPolicy = (idx: number) => {
    return () => {
      history.replaceState(undefined, '', `#${idx}`);
      setActiveIdx(idx);
      scrollTo({ top: 0 });
    };
  };

  return (
    <Layout>
      <PC className={styles.pcContainer}>
        <div className={styles.wrapper}>
          <div
            className={styles.policyWrapper}
            dangerouslySetInnerHTML={{ __html: data.allMarkdownRemark.edges[activeIdx]?.node?.html }}
          ></div>
          <div className={styles.policyList}>
            {data.allMarkdownRemark.edges.map((edge: any, idx: number) => {
              return (
                <p key={idx} onClick={handleClickPolicy(idx)} className={styles.policyTitle}>
                  {edge.node.frontmatter.title}
                </p>
              );
            })}
          </div>
        </div>
      </PC>
      <Mobile className={styles.mContainer}>
        <div className={styles.wrapper}>
          <div
            className={styles.policyWrapper}
            dangerouslySetInnerHTML={{ __html: data.allMarkdownRemark.edges[activeIdx]?.node?.html }}
          ></div>
          <div className={styles.policyList}>
            {data.allMarkdownRemark.edges.map((edge: any, idx: number) => {
              return (
                <p key={idx} onClick={handleClickPolicy(idx)} className={styles.policyTitle}>
                  {edge.node.frontmatter.title}
                </p>
              );
            })}
          </div>
        </div>
      </Mobile>
    </Layout>
  );
}
